<template>
  <Transition
    mode="in-out"
    name="fade"
  >
    <!-- Boards Public View -->
    <div
      v-if="isAuthorized"
      class="w-full h-full  relative bg-none"
    >
      <!-- Whitelabel -->
      <transition
        name="fadeHeader"
        mode="out-in"
      >
        <WhiteLabelHeader
          v-if="lensTeam !== null"
          hide-dupe-opt
          :board-team="lensTeam"
          :shared-by="sharedByUser"
          share-type="report"
        />
        <WhiteLabelHeader
          v-else
          :board-team="lensTeam"
          hide-dupe-opt
          :shared-by="sharedByUser"
          share-type="report"
          :show-default="true"
        />
      </transition>

      <!-- Note @sam: the width excess is used for comments -->
      <MainLayout
        scroll-toolbar
        bg-color="white"
        aditional-classes="ml-4"
        no-content-padding
        style="height: calc(100vh - 88px); width: calc(100vw - 30px); z-index: 99; position: relative;"
      >
        <!-- Toolbar -->
        <template #toolbar>
          <div class="flex items-center justify-between p-5">
            <div class="flex items-center gap-2">
              <ReportIcon class="text-icon-muted" />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                {{ sharedReport?.name }}
              </BaseText>
              <BaseText
                size="sm"
                class="text-text-normal"
              >
                {{ sharedReport?.description }}
              </BaseText>
            </div>
            <!-- TODO @Sam: date -->
            <div class="flex items-center gap-1.5">
              <CalendarIcon />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                {{ dateRangeLabel }}
              </BaseText>
            </div>
          </div>
        </template>

        <template #content>
          <div class="flex flex-col gap-12 px-3">
            <LensReportGraph
              is-public
              :graph-type="sharedReport?.graph"
              :data-items="graphDataItems"
              :data-summary="graphDataSummary"
              :result-count="tableResults?.ad_groups?.length"
              :selected-kpis="selectedColumns"
            />
            <Transition
              name="tableFade"
              mode="out-in"
            >
              <div
                v-if="loading.adGroups || tableResults?.ad_groups?.length === 0"
                class="grid grid-cols-12 grid-rows-6 gap-0 border relative border-neutral-50 mr-3 mt-3 rounded-lg"
              >
                <!-- Loading text-->
                <div
                  v-if="loading.adGroups"
                  class="absolute w-full h-full flex flex-col items-center justify-center gap-1 text-center"
                >
                  <div>
                    <BaseLoadingSpinnerCircle
                      small
                      :width="24"
                      :height="24"
                      class="text-text-muted"
                    />
                  </div>
                  <BaseText
                    class="text-text-muted"
                    type="label"
                    size="sm"
                  >
                    Loading Data...
                  </BaseText>
                  <BaseText
                    class="text-text-normal w-64"
                    size="sm"
                  >
                    One moment while we load the data. This might take up to a minute.
                  </BaseText>
                </div>
                <div
                  v-else-if="tableResults?.ad_groups?.length === 0"
                  class="absolute w-full h-full flex flex-col items-center justify-center gap-1 text-center"
                >
                  <div>
                    <WarningIcon class="text-icon-normal" />
                  </div>
                  <BaseText
                    class="text-text-muted"
                    type="label"
                    size="sm"
                  >
                    No data found
                  </BaseText>
                  <BaseText
                    class="text-text-normal w-64"
                    size="sm"
                  >
                    Try changing your filters
                  </BaseText>
                </div>
                <!-- Grid background -->
                <div
                  v-for="index in 36"
                  :key="index"
                  :class="{
                    'border-b': index <= 30,
                    'border-r': index % 30 !== 0,
                  }"
                  class="border-neutral-50 h-20 col-span-2"
                />
              </div>
              <LensPublicMetricTable
                v-else-if="tableResults?.ad_groups?.length > 0"
                :grouped-by="'ad_name'"
                :loading="loading.adGroups"

                :graph-info="graphInfo"
                :selected-columns.sync="selectedColumns"
                :selected-rows.sync="selectedRows"

                :table-columns="sharedReport.tableColumns"
                :current-sort="sharedReport.sort"
                :table-config="sharedReport.tableConfig"
                :max-row-count="tableResults?.ad_groups?.length"
                :summary-stats="sharedReport?.summaryStats"
                :data="tableResults"

                @sort="sortTableResults"
                @pin="pinColumn"
              />
            </Transition>
          </div>
        </template>
      </MainLayout>
    </div>
    <!-- Password auth flow -->
    <div
      v-else
      class="relative bg-none"
      style="height: 100vh; width: 100%"
    >
      <div
        class="flex flex-col gap-5 items-center justify-center w-full h-full relative z-10"
      >
        <div
          v-if="!loading.auth"
          class="flex items-center gap-3"
        >
          <img
            :src="lensTeam?.avatar || fallbackAvatar"
            class="rounded-md w-8 h-8"
          >
          <BaseText
            type="label"
            size="lg"
            class="text-white"
          >
            {{ lensTeam?.whiteLabel?.companyName }}
          </BaseText>
        </div>
        <ShareReportPasswordBlock
          :shared-by="sharedByUser"
          :page-loading="loading.auth"
          :loading="loading.unlock"
          @unlock="attemptUnlock"
        />
      </div>
      <!-- TODO @Sam: update gradient -->
      <div class="absolute top-0 right-0 w-full h-full select-none">
        <div class="w-full h-full relative  overflow-x-hidden z-0">
          <WhiteLabelGradient :fill="lensTeam?.whiteLabel?.companyColor" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import LensAPI from '@/api/lens'
import { mapActions } from 'vuex'
import FirebaseAPI from '@/api/firebase'

// Components
import MainLayout from '../../layouts/MainLayout'
import WhiteLabelHeader from '../../components/account/whiteLabel/WhiteLabelHeader.vue'
import ReportIcon from '../../components/globals/Icons/LensIcons/ReportIcon.vue'
import LensPublicMetricTable from '../../components/lens/table/LensPublicMetricTable.vue'
import LensReportGraph from '../../components/lens/graphs/LensReportGraph.vue'
import WhiteLabelGradient from '../../components/account/whiteLabel/WhiteLabelGradient.vue'
import ShareReportPasswordBlock from '../../components/lens/report/share/ShareReportPasswordBlock.vue'
// Icons
import CalendarIcon from '../../components/globals/Icons/CalendarIcon.vue'
export default {
  name: 'LensReportPublicView',
  components: {
    MainLayout,
    WhiteLabelHeader,
    LensPublicMetricTable,
    LensReportGraph,
    WhiteLabelGradient,
    ShareReportPasswordBlock,
    // Icons
    CalendarIcon,
    ReportIcon
  },
  data () {
    return {
      shareReportId: null,

      lensTeam: null,
      fallbackAvatar: require('../../assets/images/defaultProfileImage.webp'),

      loading: {
        adGroups: true,
        unlock: false,
        auth: false
      },

      isAuthorized: false,

      sharedByUser: null,
      sharedReport: null,
      tableResults: [],
      selectedRows: [],
      selectedColumns: []
    }
  },
  computed: {
    dateRangeLabel () {
      const { start, end } = this.sharedReport?.dateRange || {}
      if (!start || !end) {
        return 'All Time'
      } else {
        const options = { year: 'numeric', month: 'short', day: '2-digit' }
        const startDate = new Date(start).toLocaleDateString('en-US', options)
        const endDate = new Date(end).toLocaleDateString('en-US', options)
        return `${startDate} to ${endDate}`
      }
    },
    graphDataItems () {
      return this.tableResults?.ad_groups?.length > 0 ? this.selectedRows : []
    },
    graphDataSummary () {
      return {
        ...this.sharedReport?.summaryStats,
        startDate: this.sharedReport?.dateRange?.start,
        endDate: this.sharedReport?.dateRange?.end
      }
    },
    graphInfo () {
      return {
        selectedGraph: this.sharedReport?.graph,
        data: {
          bar: {
            selected: this.sharedReport?.selected_columns?.length || 0,
            total: 4
          },
          line: {
            selected: this.sharedReport?.selected_columns?.length || 0,
            total: 4
          },
          grid: {
            selected: this.sharedReport?.selected_columns?.length || 0,
            total: 12
          }
        }
      }
    }
  },
  async mounted () {
    this.loading.adGroups = true
    this.loading.auth = true
    this.shareReportId = this.$route.params.reportId
    // Fetch only public data & prompt for password if required
    const data = await this.fetchPublicSharedReport(this.shareReportId)
    if (!data.created_by) {
      this.$showAlert({ type: 'error', message: 'Report not found' })
      return
    }
    this.sharedByUser = await FirebaseAPI.Users.getById(data.created_by)
    if (data?.team_id) {
      this.lensTeam = await FirebaseAPI.Teams.getByID(data.team_id)
    }
    console.log(this.lensTeam)
    this.isAuthorized = !data.access_password
    this.loading.auth = false
    await this.fetchSharedReport(this.shareReportId)
  },
  methods: {
    ...mapActions('LensModule', ['updateColorFormat']),
    sortTableResults (sort) {
      this.sharedReport.sort = sort
      const { by, order } = sort
      this.tableResults.ad_groups.sort((a, b) => {
        if (a[by] < b[by]) return order === 'asc' ? -1 : 1
        if (a[by] > b[by]) return order === 'asc' ? 1 : -1
        return 0
      })
    },
    pinColumn (newCols) {
      this.sharedReport.tableColumns = newCols
    },
    async fetchPublicSharedReport (shareId) {
      try {
        const { data } = await LensAPI.LensReport.getPublicShareById(shareId)
        return data
      } catch (e) {
        console.error(e)
        this.$showAlert({ type: 'error', message: 'Internal server error' })
      }
    },
    async attemptUnlock (password) {
      this.loading.unlock = true
      try {
        const res = await LensAPI.LensReport.validateSharePassword(this.shareReportId, password)
        this.isAuthorized = true
        const { query, ...shareData } = res.data || {}
        this.setShareReport(query, shareData)
        this.loading.adGroups = false
      } catch (e) {
        this.$showAlert({ type: 'error', message: 'Incorrect password' })
      } finally {
        this.loading.unlock = false
      }
    },
    async fetchSharedReport (shareId) {
      try {
        const res = await LensAPI.LensReport.getShareById(shareId)
        const { query, ...shareData } = res.data || {}
        this.isAuthorized = true
        this.setShareReport(query, shareData)
        this.loading.adGroups = false
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.isAuthorized = false
          this.$showAlert({ type: 'info', message: 'Password required' })
        } else {
          this.$showAlert({ type: 'error', message: 'Internal server error' })
          this.loading.adGroups = false
        }
      }
    },
    setShareReport (query, shareData) {
      this.sharedReport = {
        ...shareData,
        graph: query.graph_type,
        sort: query.sorted_column,
        selectedColumns: query.selected_columns,
        tableConfig: query.table_config,
        tableColumns: query.table_columns,
        summaryStats: query.summary_stats,
        dateRange: query?.date_range
      }
      this.lensId = this.sharedReport.lens_id

      this.updateColorFormat(this.sharedReport?.tableConfig.colorFormat)
      this.selectedRows = query.snapshot
      this.selectedColumns = this.sharedReport?.selectedColumns
      this.tableResults = { ad_groups: query.snapshot }
    }

  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fadeHeader-enter-active,
.fadeHeader-leave-active {
  transition: all 0.2s ease-out;
  opacity: 1;
}

.fadeHeader-enter,
.fadeHeader-leave-to {
  opacity: 0;
}

.tableFade-enter-active,
.tableFade-leave-active {
  transition: all 0.25s ease-out;
}

.tableFade-enter-from {
  opacity: 0;
  transform: translateY(1rem);
}

.tableFade-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>

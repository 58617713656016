<template>
  <div>
    <div
      v-if="pageLoading"
      class="report-lock flex items-center justify-center py-12"
    >
      <BaseLoadingSpinnerCircle
        class="text-white"
        :width="120"
        :height="120"
      />
    </div>
    <div
      v-else
      class="report-lock"
    >
      <div class="flex items-center justify-center gap-1.5 rounded-lg">
        <img
          class="rounded-full w-5 h-5"
          :src="sharedBy?.avatar"
        >
        <BaseText
          type="label"
          size="sm"
          class="text-white"
        >
          {{ sharedByName }}
        </BaseText>
        <BaseText
          size="sm"
          class="text-neutral-alpha-700"
        >
          shared this report with you.
        </BaseText>
      </div>
      <!-- Divider -->
      <div class="w-full border-t border-neutral-alpha-200 my-4" />
      <div class="flex flex-col gap-3 px-10 py-5 text-center items-center">
        <LockedIcon class="text-white w-6 h-6" />
        <div class="px-5 space-y-1">
          <BaseText
            type="label"
            class="text-white"
          >
            Please enter the password
          </BaseText>
          <BaseText
            size="sm"
            class="text-neutral-alpha-650"
          >
            If you haven't received any password, please contact {{ sharedByName }} to receive the password
          </BaseText>
        </div>
      </div>
      <!-- Divider -->
      <div class="w-full border-t border-neutral-alpha-200 my-4" />
      <div class="flex flex-col gap-3">
        <div class="space-y-1">
          <BaseText
            type="label"
            size="sm"
            class="text-white"
          >
            Password
          </BaseText>

          <input
            v-model="reportForm.password"
            type="password"
            placeholder="Enter the password"
            class="form-input min-w-0 w-full px-2 py-1.5"
          >
        </div>
        <button
          class="unlock-btn flex items-center justify-center"
          :disabled="loading"
          @click="attemptUnlock"
        >
          <BaseLoadingSpinnerCircle
            v-if="loading"
            class="text-white"
          />
          <BaseText
            v-else
            type="label"
            size="sm"
          >
            Unlock
          </BaseText>
        </button>
      </div>
    </div>
    <a
      class="flex items-center gap-1 justify-center w-full mt-5 opacity-75 hover:opacity-50 transition-opacity"
      href="https://foreplay.co"
      rel="nofollow"
      target="_blank"
    >
      <BaseText
        size="sm"
        class="text-white"
      >
        Powered by
      </BaseText>
      <a>
        <img
          src="../../../../assets/images/brief-foreplay-icon.svg"
          alt=""
          class="filter invert h-7"
        >
      </a>
    </a>
  </div>
</template>

<script>
import LockedIcon from '../../../globals/Icons/LockedIcon.vue'

export default {
  name: 'ShareReportPasswordBlock',
  components: {
    LockedIcon
  },
  props: {
    pageLoading: {
      type: Boolean,
      default: () => false
    },
    sharedBy: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {

      reportForm: {
        password: ''
      }
    }
  },
  computed: {
    sharedByName () {
      const { first_name: firstName, last_name: lastName, name, email } = this.sharedBy || {}
      if (firstName && lastName) {
        return `${firstName} ${lastName}`
      } else if (name) {
        return name
      }
      return email
    }
  },
  methods: {
    attemptUnlock () {
      this.$emit('unlock', this.reportForm.password)
    }
  }
}
</script>

<style scoped>
.report-lock{
  border-radius: 16px;
  padding: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), rgba(0, 0, 0, 0.68);
  background: linear-gradient(0deg, color(display-p3 1 1 1 / 0.12) 0%, color(display-p3 1 1 1 / 0.12) 100%), color(display-p3 0 0 0 / 0.68);
  backdrop-filter: blur(40px);
  width: 480px;
}

.form-input{
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.08);
  background: color(display-p3 1 1 1 / 0.08);
  color: white;
}

.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}

.form-input::placeholder{
  color: rgba(255, 255, 255, 0.44)
}

.unlock-btn{
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), rgba(255, 255, 255, 0.20);
  background: linear-gradient(180deg, color(display-p3 1 1 1 / 0.12) 0%, color(display-p3 1 1 1 / 0.00) 100%), color(display-p3 1 1 1 / 0.20);
}
.unlock-btn:disabled{
  opacity: 80%;
}
</style>
